<template>
  <div v-if="hasOrganisers" class="mt-4 px-4 lg:mt-8 lg:px-8">
    <div :class="['w-full', centered ? 'max-w-screen-2xl mx-auto' : null]">
      <div class="grid gap-4 grid-cols-1 2xl:grid-cols-4 sm:grid-cols-2 lg:grid-cols-3">
        <MoleculeOrganiser v-for="organiser in organisers" :key="organiser.uid" :organiser="organiser" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      organisers: Object,
      centered: Boolean
    },
    emits: ['update-organisers'],
    computed: {
      hasOrganisers() {
        return this.organisers && !!this.organisers.length
      }
    },
    methods: {
      updatOrganiserList(organiserUid) {
        this.$emit('update-organisers', organiserUid)
      }
    }
  }
</script>
