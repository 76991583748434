<template>
  <footer class="text-black antialiased bg-white" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="mx-auto px-4 py-8 max-w-screen-2xl lg:px-8">
      <div class="mx-auto max-w-screen-2xl">
        <div class="md:flex md:items-center md:justify-between">
          <ul class="flex space-x-6 md:order-2">
            <li v-for="link in socialLinks" :key="link.url">
              <a
                :href="link.url"
                class="lg:hover:text-orange-500 text-black transition-colors duration-150 ease-in-out"
                target="_blank"
                rel="noreferrer"
              >
                <span class="sr-only">{{ link.title }}</span>
                <BaseIcon :icon-name="link.title">
                  <component :is="link.icon" />
                </BaseIcon>
              </a>
            </li>
          </ul>
          <p class="mt-8 text-base md:order-1 md:mt-0">&copy; {{ currentYear }} Tracklimit Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        socialLinks: [
          {
            title: 'Facebook',
            icon: 'FacebookIcon',
            url: 'https://www.facebook.com/tracklimithq'
          },
          {
            title: 'Instagram',
            icon: 'InstagramIcon',
            url: 'https://www.instagram.com/tracklimithq/'
          }
        ]
        // primaryLinks: [
        //   {
        //     title: 'Events',
        //     url: '/events'
        //   },
        //   {
        //     title: 'Race Series',
        //     url: '/races'
        //   },
        //   {
        //     title: 'Photographs',
        //     url: '/photographs'
        //   },
        //   {
        //     title: 'Leaderboards',
        //     url: '/leaderboards'
        //   },
        //   {
        //     title: 'Members',
        //     url: '/members'
        //   },
        //   {
        //     title: 'Circuits',
        //     url: '/circuits'
        //   },
        //   {
        //     title: 'Organisers',
        //     url: '/organisers'
        //   }
        // ],
        // accountLinks: [
        //   {
        //     title: 'Dashboard',
        //     url: '/dashboard'
        //   },
        //   {
        //     title: 'Settings',
        //     url: '/account/settings'
        //   }
        // ],
        // tertiaryLinks: [
        //   {
        //     title: 'About',
        //     url: '/about-us'
        //   },
        //   {
        //     title: 'News',
        //     url: '/news'
        //   },
        //   {
        //     title: 'Terms & Conditions',
        //     url: '/terms-and-conditions'
        //   },
        //   {
        //     title: 'Privacy Policy',
        //     url: '/privacy-policy'
        //   },
        //   {
        //     title: 'Contact',
        //     url: '/contact'
        //   }
        // ]
      }
    },
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser'
      }),
      currentYear() {
        return new Date().getFullYear()
      }
    }
  }
</script>
