<template>
  <div v-if="filters && !!filters.fields.length" class="px-4 border-b border-gray lg:px-8">
    <MoleculeFilters
      :fields="filters.fields"
      :cta="filters.cta"
      :action="filters.action"
      classes="mx-auto mt-4 lg:mt-6 lg:flex pb-8 lg:items-center"
      @update-content="update"
    />
  </div>
  <div class="mt-4 px-4 lg:mt-8 lg:px-8">
    <div :class="['w-full', centered ? 'max-w-screen-2xl mx-auto' : null]">
      <div
        v-if="hasVideos"
        :class="[
          'grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3',
          !loggedInUser ? 'xl:grid-cols-3' : null,
          centered ? null : '3xl:grid-cols-4'
        ]"
      >
        <AtomVideo
          v-for="video in videos"
          :key="video.uid"
          :video="video"
          :is-event="isEvent"
          @deleted="updateVideoList"
        />
      </div>
      <!--<div v-else><p>No videos found</p></div>-->
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      videos: Array,
      isEvent: Boolean,
      centered: {
        type: Boolean,
        default: false
      },
      filters: Object
    },
    emits: ['delete-video', 'update-content'],
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser'
      }),
      hasVideos() {
        return this.videos && !!this.videos.length
      }
    },
    methods: {
      updateVideoList(videoUid) {
        this.$emit('delete-video', videoUid)
      },
      update(filters) {
        this.$emit('update-content', filters)
      }
    }
  }
</script>
