<template>
  <div v-if="hasCircuits" class="mt-4 px-4 lg:mt-8 lg:px-8">
    <div :class="['w-full', centered ? 'max-w-screen-2xl mx-auto' : null]">
      <div class="grid gap-4 grid-cols-1 2xl:grid-cols-3 lg:grid-cols-2">
        <MoleculeCircuit
          v-for="circuit in circuits"
          :key="circuit.name"
          :circuit="circuit"
          @deleted="updateCircuitList"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      circuits: Object,
      centered: Boolean
    },
    emits: ['update-circuits'],
    computed: {
      hasCircuits() {
        return this.circuits && !!this.circuits.length
      }
    },
    methods: {
      updateCircuitList(circuitUid) {
        this.$emit('update-circuits', circuitUid)
      }
    }
  }
</script>
