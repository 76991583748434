<template>
  <template v-if="hasCars">
    <div class="mt-4 px-4 lg:mt-8 lg:px-8">
      <div :class="['w-full', centered ? 'max-w-screen-2xl mx-auto' : null]">
        <div class="grid gap-4 grid-cols-1">
          <AtomCar v-for="car in cars" :key="car.uid" :car="car" @deleted="updateCarList" />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
  export default {
    props: {
      cars: Array,
      centered: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update-cars'],
    computed: {
      hasCars() {
        return this.cars && !!this.cars.length
      }
    },
    methods: {
      updateCarList(carUid) {
        this.$emit('update-cars', carUid)
      }
    }
  }
</script>
