<template>
  <div v-if="filters && !!filters.fields.length" class="px-4 border-b border-gray lg:px-8">
    <MoleculeFilters
      :fields="filters.fields"
      :cta="filters.cta"
      :action="filters.action"
      classes="mx-auto mt-4 lg:mt-6 lg:flex pb-8 lg:items-center"
      @update-content="update"
    />
  </div>

  <div v-if="hasAlbums" class="mt-4 px-4 lg:mt-8 lg:px-8">
    <div :class="['w-full', centered ? 'max-w-screen-2xl mx-auto' : null]">
      <div :class="['grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3', centered ? null : '3xl:grid-cols-4']">
        <MoleculeAlbum
          v-for="album in albums"
          :key="album.uid"
          :album="album"
          :on-organiser="onOrganiser"
          :on-dashboard="onDashboard"
          :on-circuit="onCircuit"
          :on-event="onEvent"
          @deleted="updateAlbumList"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      albums: Array,
      filters: Object,
      centered: {
        type: Boolean,
        default: false
      },
      onDashboard: {
        type: Boolean,
        default: false
      },
      onCircuit: {
        type: Boolean,
        default: false
      },
      onEvent: {
        type: Boolean,
        default: false
      },
      onOrganiser: {
        type: Boolean,
        default: false
      }
    },
    emits: ['delete-album', 'update-content'],
    computed: {
      hasAlbums() {
        return this.albums && !!this.albums.length
      }
    },
    methods: {
      updateAlbumList(albumUid) {
        this.$emit('delete-album', albumUid)
      },
      update(filters) {
        this.$emit('update-content', filters)
      }
    }
  }
</script>
