<template>
  <template v-if="hasCircuits">
    <div class="mx-auto my-4 w-full lg:my-8">
      <MoleculeCircuitLayout
        v-for="(details, name, idx) in circuits"
        :key="name"
        :circuit-name="name"
        :leaderboards="details"
        :class="[
          'flex flex-wrap justify-between flex-col px-4 lg:px-8 pb-4 lg:pb-8 border-b border-gray',
          idx > 0 ? 'mt-8' : null
        ]"
        @update-laps="updateLapList"
      />
    </div>
  </template>
</template>

<script>
  export default {
    props: {
      circuits: Object
    },
    emits: ['update-laps'],
    computed: {
      hasCircuits() {
        return this.circuits && Object.keys(this.circuits).length
      }
    },
    methods: {
      updateLapList(lapUid) {
        this.$emit('update-laps', lapUid)
      }
    }
  }
</script>
