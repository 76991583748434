<template>
  <div ref="wrapper" class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen">
      <div class="fixed inset-0" aria-hidden="true">
        <div class="absolute inset-0 bg-black" @click="closeOverlay"></div>
      </div>
      <Transition
        enter-active-class="transform transition ease-out duration-300 delay-150"
        enter-from-class="opacity-0 translate-y-8"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transform transition ease-in duration-300"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-8"
        appear
      >
        <div class="fixed top-0 w-full h-full bg-white overflow-y-auto lg:flex lg:bg-transparent lg:overflow-hidden">
          <div
            class="flex flex-1 justify-center bg-black overflow-y-auto lg:items-center lg:h-full"
            :class="[videoUrl ? '2xl:p-16' : null]"
            @click="closeOverlay"
          >
            <div
              :class="[
                videoUrl
                  ? 'aspect-w-16 aspect-h-9'
                  : !isImageLoaded
                  ? 'aspect-w-3 aspect-h-2 lg:p-0 lg:h-screen overflow-hidden'
                  : 'lg:aspect-w-3 lg:aspect-h-2 lg:p-0 lg:h-screen overflow-hidden'
              ]"
              class="relative w-full"
            >
              <div
                :class="[
                  'flex text-white bg-black items-center lg:absolute justify-center w-full h-full max-h-screen transform-gpu transition-all ease-in-out duration-300',
                  isImageLoaded ? 'absolute opacity-0' : 'opacity-100'
                ]"
                @click="closeOverlay"
              >
                <AtomLogo :width="98" :height="58" class="animate-pulse" />
              </div>

              <iframe
                v-if="videoUrl"
                class="absolute w-full h-full"
                :src="videoUrl"
                allowfullscreen
                allow="autoplay"
                frameborder="0"
                title="Overlay"
              ></iframe>

              <img
                v-else-if="imageUrl"
                :class="[
                  'lg:absolute w-full h-auto max-h-screen object-contain z-10 lg:mx-auto lg:top-1/2 lg:-translate-y-1/2 transform-gpu transition-opacity ease-in-out duration-300',
                  !isImageLoaded ? 'opacity-0' : 'opacity-100',
                  isSidebarVisible ? 'max-w-screen-2xl' : 'max-w-screen-4xl'
                ]"
                :src="imageUrl"
                :alt="overlay.title"
                @load="imageLoaded"
              />

              <img
                v-if="imageUrl"
                :class="[
                  'hidden md:block absolute w-full h-full object-cover filter blur-lg scale-150 transform-gpu transition-opacity ease-in-out duration-300',
                  !isImageLoaded ? 'opacity-0' : isSidebarVisible ? 'opacity-50' : 'opacity-0'
                ]"
                :src="imageUrl"
                :alt="overlay.title"
                @load="imageLoaded"
              />
            </div>
          </div>
          <div
            :class="[isSidebarVisible ? 'flex' : 'hidden']"
            class="w-full bg-white overflow-y-auto lg:w-96 lg:h-full"
          >
            <aside v-if="overlay.uid" class="mt-4 w-full space-y-4">
              <AtomSectionHeader
                v-if="overlay.title"
                :sidebar="true"
                classes="px-4"
                :title="overlay.title"
                heading-tag="h2"
              />
              <ul v-if="overlay.meta" class="grid gap-2 grid-cols-2 items-center px-4">
                <li v-if="overlay.meta.username" class="flex items-center overflow-hidden">
                  <RouterLink
                    v-if="profileUrl"
                    :to="profileUrl"
                    class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
                    title="View profile"
                  >
                    <BaseIcon class="flex-none mr-1 w-7 h-7">
                      <PersonIcon />
                    </BaseIcon>
                    {{ videoOwner }}
                  </RouterLink>
                </li>
                <li v-if="overlay.meta.date" class="flex items-center">
                  <RouterLink
                    v-if="eventUrl"
                    :to="eventUrl"
                    class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
                    title="View event details"
                  >
                    <BaseIcon class="flex-none mr-1 w-7 h-7">
                      <EventIcon />
                    </BaseIcon>
                    <AtomFormattedDate :date="overlay.meta.date" format="short" />
                  </RouterLink>
                </li>
                <li v-if="overlay.meta.lap_time" class="flex items-center">
                  <p class="flex items-center">
                    <BaseIcon class="flex-none mr-1 w-7 h-7">
                      <ClockIcon />
                    </BaseIcon>
                    <AtomTime :duration="overlay.meta.lap_time" />
                  </p>
                </li>
                <li v-if="overlay.meta.weather" class="flex items-center">
                  <p class="flex items-center capitalize">
                    <BaseIcon class="flex-none mr-1 w-7 h-7">
                      <WeatherIcon />
                    </BaseIcon>
                    {{ overlay.meta.weather }}
                  </p>
                </li>
              </ul>
              <template v-if="hasTags">
                <!-- <AtomSectionHeader
                  :sidebar="true"
                  :heading-classes="[videoUrl ? 'text-2xl pt-4 border-t border-gray' : 'text-3xl']"
                  classes="px-4"
                  title="Tags"
                  heading-tag="h2"
                /> -->
                <ul class="mx-4 pb-4 border-b border-gray space-y-1">
                  <li v-for="tag in overlay.tags" :key="tag.username" class="flex items-center overflow-hidden">
                    <RouterLink
                      v-if="tag.username"
                      :to="`/${tag.username}`"
                      class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
                      title="View profile"
                    >
                      <BaseIcon class="flex-none mr-1 w-7 h-7">
                        <PersonIcon />
                      </BaseIcon>
                      {{ tag.username }}
                    </RouterLink>
                  </li>
                </ul>
              </template>
              <template v-if="content">
                <AtomSectionHeader
                  :sidebar="true"
                  :heading-classes="[videoUrl ? 'text-2xl pt-4 border-t border-gray' : 'text-3xl']"
                  classes="px-4"
                  title="Description"
                  heading-tag="h2"
                />
                <p class="px-4" v-html="content"></p>
              </template>
              <template v-if="car">
                <AtomSectionHeader
                  :sidebar="true"
                  :heading-classes="[videoUrl ? 'text-2xl pt-4 border-t border-gray' : 'text-3xl']"
                  classes="px-4"
                  title="Car Info"
                  heading-tag="h2"
                />
                <p v-if="carDescription" class="px-4" v-html="carDescription"></p>
              </template>
              <AtomSectionHeader
                :sidebar="true"
                :heading-classes="[videoUrl ? 'text-2xl pt-4 border-t border-gray' : 'text-3xl']"
                classes="px-4"
                title="Comments"
                heading-tag="h2"
              />
              <OrganismComments
                :sidebar="true"
                :comments="comments"
                :author-uid="overlay.owner_uid"
                :content-uid="overlay.uid"
                @update-comments="updateCommentList"
              />
            </aside>
          </div>
        </div>
      </Transition>
      <button
        class="
          group
          lg:hover:bg-orange-500
          absolute
          left-4
          top-4
          inline-flex
          items-center
          justify-center
          w-10
          h-10
          text-white text-sm
          font-medium
          bg-black bg-opacity-40
          border-0
          rounded-full
          focus:outline-none
          overflow-hidden
          transform
          transition
          duration-300
          ease-in-out
        "
        title="Close"
        @click="closeOverlay"
      >
        <BaseIcon><CloseIcon /></BaseIcon>
      </button>
      <div class="absolute top-4 flex gap-2" :class="[isSidebarVisible ? 'right-4 lg:right-100' : 'right-4']">
        <button
          v-if="loggedInUser && notOwner"
          :class="[
            'text-white rounded-full disabled:opacity-50 disabled:cursor-default w-10 h-10 inline-flex items-center justify-center border-0 text-sm font-medium focus:outline-none transition-colors ease-in-out duration-300',
            overlay.liked
              ? 'bg-orange-500 lg:hover:bg-orange-300 active:bg-orange-700'
              : 'bg-black bg-opacity-40 lg:hover:bg-orange-500'
          ]"
          :disabled="isLiking"
          :title="overlay.liked ? 'Unlike photograph' : 'Like photograph'"
          @click.prevent="like"
        >
          <BaseIcon class="w-7 h-7"><LoveIcon /></BaseIcon>
        </button>
        <button
          class="
            group
            lg:hover:bg-orange-500
            hidden
            items-center
            justify-center
            w-10
            h-10
            text-white text-sm
            font-medium
            bg-black bg-opacity-40
            border-0
            rounded-full
            focus:outline-none
            overflow-hidden
            transform
            transition
            duration-300
            ease-in-out
            lg:flex
          "
          :title="[isSidebarVisible ? 'Maximise' : 'Minimise']"
          @click="toggleSidebar"
        >
          <BaseIcon>
            <MaximizeIcon v-if="isSidebarVisible" />
            <MinimizeIcon v-else />
          </BaseIcon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    emits: ['close'],
    data() {
      return {
        isImageLoaded: false,
        isSidebarVisible: true,
        comments: null,
        isLiking: false
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        overlay: 'getOverlay',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      videoUrl() {
        return this.overlay && this.overlay.youtube_id
          ? `https://www.youtube.com/embed/${this.overlay.youtube_id}?autoplay=1`
          : null
      },
      imageUrl() {
        return this.overlay && this.overlay.imagePath ? this.overlay.imagePath : null
      },
      eventUrl() {
        return this.overlay.meta.url ? `/event/${this.overlay.meta.url}` : null
      },
      notOwner() {
        return this.loggedInUser.id !== this.overlay.owner_uid
      },
      profileUrl() {
        return this.overlay.meta.username ? `/${this.overlay.meta.username}` : null
      },
      videoOwner() {
        const uuidRegEx = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

        return !uuidRegEx.test(this.overlay.meta.username)
          ? this.overlay.meta.username
          : this.overlay.meta.first_name && this.overlay.meta.last_name
          ? `${this.overlay.meta.first_name} ${this.overlay.meta.last_name}`
          : this.overlay.meta.username
      },
      content() {
        return this.overlay && this.overlay.content ? this.overlay.content.replace(/(?:\r\n|\r|\n)/g, '<br>') : null
      },
      car() {
        return this.overlay && this.overlay.meta && this.overlay.meta.car && this.overlay.meta.car.description
          ? this.overlay.meta.car
          : null
      },
      carDescription() {
        return this.car && this.car.description
          ? this.car.description.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/\*([^\s][^\*]+?[^\s])\*/g, '<b>$1<\/b>')
          : null
      },
      hasTags() {
        return !!this.overlay.tags
      }
    },
    created() {
      this.loadContent()
    },
    mounted() {
      this.overlay.url ? this.$router.push({ path: this.$route.path, query: { u: this.overlay.url } }) : null
    },
    unmounted() {
      // Remove event listener for esc key
    },
    methods: {
      async loadContent() {
        await this.getComments()
      },
      async getComments() {
        const headers = {}

        if (this.userJWT) headers.Authorization = this.userJWT

        try {
          this.comments = await this.apiRequest('/comment/comments', null, { u: this.overlay.uid }, headers)

          if (!!!this.comments) this.comments = []
        } catch (e) {
          if (e) console.log(`Error getting comments: ${e.status} ${e.data}`)
        }
      },
      async updateCommentList(commentUid) {
        if (commentUid) {
          this.comments = this.comments.filter((comment) => {
            return comment.uid !== commentUid
          })
        } else {
          this.getComments()
        }
      },
      async like() {
        try {
          this.isLiking = true

          await this.apiRequest(
            `/like`,
            this.overlay.liked
              ? {
                  u: this.overlay.uid
                }
              : {
                  album_uid: this.overlay.album_uid ? this.overlay.album_uid : null,
                  photograph_uid: this.overlay.photograph_uid ? this.overlay.photograph_uid : null,
                  video_uid: this.overlay.video_uid ? this.overlay.video_uid : null,
                  n: this.overlay.owner_uid,
                  o: this.overlay.organisers_album ? this.overlay.organisers_album : null
                },
            null,
            {
              Authorization: this.userJWT
            },
            this.overlay.liked ? 'DELETE' : 'POST'
          )

          this.isLiking = false

          this.overlay.liked = !this.overlay.liked
        } catch (e) {
          if (e) console.log(`Error updating like: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't update your like, please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
          this.isLiking = false
        }
      },
      imageLoaded() {
        this.isImageLoaded = true
      },
      toggleSidebar() {
        this.isSidebarVisible = !this.isSidebarVisible
      },
      closeOverlay() {
        this.$router.replace({ query: null })
        this.$emit('close')
        this.isSidebarVisible = true
      }
    }
  }
</script>

<style scoped>
  .slide-scale-enter-from {
    opacity: 0;
    transform: translateY(30px);
  }

  .slide-scale-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }

  .slide-scale-enter-active {
    transition: all 270ms ease-out 180ms;
  }

  .slide-scale-leave-active {
    transition: all 270ms ease-in;
  }

  .slide-scale-enter-to,
  .slide-scale-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
</style>
