<template>
  <div
    id="Comments"
    class="px-4 space-y-2"
    :class="[
      feed
        ? 'mt-4 lg:px-4'
        : sidebar
        ? 'mt-4 pb-16 lg:pb-8'
        : !loggedInUser && !hasComments
        ? 'lg:px-8'
        : 'mt-4 lg:mt-8'
    ]"
  >
    <div class="mx-auto max-w-screen-2xl space-y-2">
      <MoleculeComments
        v-if="hasComments && showComments"
        :parent-uid="contentUid"
        :overlay-uid="overlayUid"
        :comments="comments"
        :feed="feed"
        :highlight-uid="replyToUid"
        @update-comments="updateCommentList"
      />
      <div v-if="loggedInUser" class="flex items-start space-x-4">
        <div v-if="!feed" class="relative">
          <img
            v-if="hasCoverPhoto"
            class="flex items-center justify-center w-10 h-10 rounded-full ring-white ring-8"
            :src="coverPhoto"
            :alt="loggedInUser.username"
          />
          <div v-else class="flex items-center justify-center p-2 w-10 h-10 bg-gray rounded-full"><AtomLogo /></div>
        </div>
        <MoleculeForm
          :initial-values="form.initial"
          :fields="feed ? feed.fields : form.fields"
          :cta="feed ? feed.cta : form.cta"
          :class="[feed ? 'relative' : null]"
          :classes="feed ? feed.classes : form.classes"
          :is-saving="isSaving"
          :reset="true"
          :inline-submit="feed"
          @form-submitted="submitForm"
        />
      </div>
      <div v-else-if="!loggedInUser">
        <RouterLink
          to="/join"
          class="
            lg:hover:bg-orange-300
            lg:hover:border-orange-300
            items-center
            justify-center
            p-2
            px-4
            w-auto
            text-white
            bg-orange-500
            border border-orange-500 border-transparent
            rounded-sm
            focus:outline-none
            transition-all
            duration-300
            ease-in-out
          "
          :class="[hasComments ? 'flex ml-14 mr-2' : 'mt-2 inline-flex']"
        >
          Join to comment
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    provide: function () {
      return {
        panel: this
      }
    },
    props: {
      authorUid: String,
      contentUid: String,
      replyToUid: String,
      eventUid: String,
      overlayUid: String,
      parentUid: String,
      comments: Array,
      feed: Boolean,
      sidebar: Boolean,
      showComments: {
        type: Boolean,
        default: true
      },
      notificationsDisabled: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update-comments'],
    data() {
      return {
        form: {
          cta: 'Post comment',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 flex-1',
          initial: {
            comment: null
          },
          fields: [
            {
              id: 'comment',
              elem: 'textarea',
              type: 'text',
              placeholder: 'Write a comment…',
              isRequired: 'true',
              classes: 'sm:col-span-2',
              inputClasses: 'border-gray bg-white'
            }
          ]
        },
        isSaving: false
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      hasComments() {
        return !!this.comments && !!this.comments.length
      },
      hasCoverPhoto() {
        return this.loggedInUser ? this.loggedInUser.cover_photo : null
      },
      coverPhoto() {
        const user = this.loggedInUser

        if (!user) return null

        return user.cover_photo
          ? `${process.env.VUE_APP_IMGIX}/users/${user.id}/${user.cover_photo}?w=64&h=64&fit=crop`
          : null
      }
    },
    methods: {
      async submitForm(values) {
        this.isSaving = true

        const data = {
          contentUid: this.contentUid,
          comment: values.comment,
          replyToUid: values.reply ? values.reply : this.replyToUid ? this.replyToUid : null,
          n: this.authorUid ? this.authorUid : null,
          e: this.eventUid,
          dn: this.notificationsDisabled
        }

        try {
          await this.apiRequest(`/comment`, data, null, { Authorization: this.userJWT }, 'POST')

          // this.$store.dispatch('setDialog', {
          //   title: 'Success',
          //   content: '<p>Comment added!</p>',
          //   ctaTitle: 'Close',
          //   confirmed: true
          // })

          this.$emit('update-comments')
        } catch (e) {
          this.form.initial.comment = data.comment

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: e ? `<p>${e.data.message}</p>` : "<p>Sorry we couldn't post your comment</p>",
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isSaving = false
      },
      async updateCommentList(commentUid) {
        this.$emit('update-comments', commentUid)
      }
    }
  }
</script>
