<template>
  <div class="flex flex-col justify-center px-4 py-12 min-h-screen lg:px-8">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      classes: String
    }
  }
</script>
