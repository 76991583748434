<template>
  <template v-if="hasMedia">
    <div class="mt-4 px-4 lg:mt-8 lg:px-8">
      <div :class="['w-full', centered ? 'max-w-screen-2xl mx-auto' : null]">
        <div :class="['grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3', centered ? null : '3xl:grid-cols-4']">
          <template v-for="item in media" :key="item.uid">
            <template v-if="item.youtube_id">
              <AtomVideo :video="item" />
            </template>
            <template v-else>
              <MoleculeAlbum :album="item" />
            </template>
          </template>
        </div>
      </div>
    </div>
  </template>
  <div v-else class="my-4 px-4 lg:my-8 lg:px-8">
    <div>
      <p class="mx-auto max-w-screen-2xl">No media found</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      media: Array,
      centered: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasMedia() {
        return this.media && !!this.media.length
      }
    }
  }
</script>
