<template>
  <div class="z-20 top-0 w-full h-20" :class="!isLanding ? 'fixed bg-white border-b border-gray' : 'absolute'">
    <div
      class="relative flex items-center mx-auto px-4 py-4 w-full max-w-screen-2xl h-20 lg:px-8"
      :class="!isLanding ? 'justify-between' : 'justify-end'"
    >
      <RouterLink
        v-if="!isLanding"
        to="/"
        title="Home"
        class="inline-flex items-center justify-center w-12 h-12 text-black"
      >
        <span class="sr-only">Go to Home</span>

        <AtomLogo />
      </RouterLink>
      <ul class="inline-flex items-center">
        <li v-if="loggedInUser && !isLanding">
          <RouterLink
            to="/dashboard"
            :class="[
              'h-12 w-8 inline-flex items-center justify-center ',
              currentPage.includes('/account') ? 'text-blue-500' : 'text-black'
            ]"
          >
            <span class="sr-only">Go to Dashboard</span>
            <BaseIcon class="w-7 h-7" icon-name="dashboard" icon-color="black">
              <DashboardIcon />
            </BaseIcon>
          </RouterLink>
        </li>
        <li v-if="loggedInUser && !isLanding">
          <RouterLink
            to="/events"
            :class="[
              'h-12 w-8 inline-flex items-center justify-center ',
              currentPage.includes('events') || currentPage.includes('event/') ? 'text-blue-500' : 'text-black'
            ]"
          >
            <span class="sr-only">Go to Events</span>
            <BaseIcon class="w-7 h-7" icon-name="search" icon-color="black">
              <EventIcon />
            </BaseIcon>
          </RouterLink>
        </li>
        <li v-if="!loggedInUser">
          <RouterLink
            :to="logInRedirectUrl"
            class="
              flex
              items-center
              justify-center
              px-4
              py-2
              w-full
              hover:text-blue-700
              text-blue-900 text-base
              font-medium
              rounded-sm
              transition-all
              duration-150
              ease-in-out
            "
          >
            Log in
          </RouterLink>
        </li>
        <li v-if="!loggedInUser && !isLanding">
          <RouterLink
            to="/join"
            class="
              flex
              items-center
              justify-center
              px-4
              py-2
              w-full
              text-white text-base
              font-medium
              hover:bg-orange-300
              bg-orange-500
              rounded-sm
              transition-all
              duration-150
              ease-in-out
            "
          >
            Join
          </RouterLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { ref, getCurrentInstance } from 'vue'
  import { mapGetters } from 'vuex'

  export default {
    setup() {
      const navigationLinks = [
        {
          title: 'Circuits',
          url: '/circuits'
        },
        {
          title: 'Organisers',
          url: '/organisers'
        },
        {
          title: 'Users',
          url: '/users'
        },
        {
          title: 'Photographs',
          url: '/photographs'
        }
      ]
      const accountNavigationLinks = [
        {
          title: 'My Profile',
          url: '/helloimtom'
        },
        {
          title: 'Dashboard',
          url: '/dashboard'
        },
        {
          title: 'Account Settings',
          url: '/account/settings'
        },
        {
          title: 'Support',
          url: '/account/support'
        }
      ]

      return {
        navigationLinks,
        accountNavigationLinks
      }
    },
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser',
        isAuthenticated: 'auth/getIsAuthenticated',
        displayProfileMenu: 'getProfileMenuDisplay'
      }),
      layout() {
        return `${this.$route.meta.layout}Layout`
      },
      isLanding() {
        return this.layout === 'LandingLayout'
      },
      currentPage() {
        return this.$route.path
      },
      logInRedirectUrl() {
        return this.currentPage === '/' ? '/sign-in' : `/sign-in?to=${this.currentPage}`
      },
      home() {
        if (this.isAuthenticated) {
          return '/dashboard'
        } else {
          return '/'
        }
      }
    },
    methods: {
      signout() {
        this.displayProfileMenu = false
        this.$store.dispatch('auth/signOut')
      }
    }
  }
</script>
