<template>
  <div class="mt-4 px-4 lg:mt-8 lg:px-8">
    <div v-if="hasMembers" :class="['w-full', centered ? 'max-w-screen-2xl mx-auto' : null]">
      <div
        :class="[
          'grid sm:grid-cols-2 md:grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4',
          centered ? null : '3xl:grid-cols-5'
        ]"
      >
        <MoleculeMember v-for="member in members" :key="member.uid" :user="member" @updated="updateMember" />
      </div>
    </div>
    <div v-else><p>No members found</p></div>
  </div>
</template>

<script>
  export default {
    props: {
      members: Array,
      centered: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update-member'],
    computed: {
      hasMembers() {
        return this.members && !!this.members.length
      }
    },
    methods: {
      updateMember(userUid, isFollowing) {
        this.$emit('update-member', userUid, isFollowing)
      }
    }
  }
</script>
