<template>
  <div class="z-100 fixed inset-0 overflow-y-auto">
    <div class="flex items-center justify-center pb-20 pt-4 px-4 min-h-screen text-center sm:p-0">
      <div class="fixed inset-0" aria-hidden="true">
        <div v-if="dialog.disableTimer" class="absolute inset-0 bg-gray bg-opacity-95"></div>
        <div v-else class="absolute inset-0 bg-gray bg-opacity-95" @click="$emit('close')"></div>
      </div>
      <Transition
        enter-active-class="transform transition ease-out duration-300 delay-150"
        enter-from-class="opacity-0 translate-y-8"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transform transition ease-in duration-300"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-8"
        appear
      >
        <dialog
          class="
            relative
            inline-block
            align-bottom
            pb-4
            pt-5
            px-4
            w-full
            text-left
            bg-white
            shadow-xl
            overflow-hidden
            transform
            transition-all
            sm:align-middle
            sm:my-8
            sm:p-6
            sm:w-full
            sm:max-w-sm
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div
              v-if="dialog.confirmed"
              class="flex items-center justify-center mx-auto w-12 h-12 bg-white border border-gray rounded-full"
            >
              <!-- Heroicon name: check -->
              <svg
                class="w-6 h-6 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <div
              v-if="dialog.error"
              class="flex items-center justify-center mx-auto w-12 h-12 bg-white border border-gray rounded-full"
            >
              <!-- Heroicon name: x -->
              <svg
                class="w-6 h-6 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div v-if="dialog.branding" class="flex items-center justify-center mx-auto w-12 h-12">
              <AtomLogo />
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 id="modal-headline" class="text-black text-lg font-medium leading-6">
                {{ dialog.title }}
              </h3>
              <div class="mt-2 text-black text-sm" v-html="dialog.content"></div>
            </div>
          </div>
          <div v-if="dialog.ctaTitle" class="mt-5 sm:mt-6">
            <component
              :is="dialog.action ? 'RouterLink' : 'button'"
              :to="dialog.action ? dialog.action : null"
              class="
                lg:hover:bg-orange-300
                inline-flex
                justify-center
                px-4
                py-2
                w-full
                text-white text-base
                font-medium
                bg-orange-500
                border border-orange-500
                rounded-sm
                focus:outline-none
                cursor-pointer
                transition-all
                duration-150
                focus:ring-orange-500
                sm:text-sm
              "
              exact-active-class="text-white"
              active-class="text-white"
              @click="$emit('close')"
            >
              {{ dialog.ctaTitle }} <template v-if="!!!dialog.disableTimer">({{ countDown }})</template>
            </component>
          </div>
        </dialog>
      </Transition>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    emits: ['close'],
    data() {
      return {
        countDown: 5
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'getDialog'
      })
    },
    created() {
      if (!!!this.dialog.disableTimer) this.countDownTimer()
    },
    methods: {
      countDownTimer() {
        let timer

        if (this.countDown >= 0) {
          timer = setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        } else {
          clearTimeout(timer)
          this.$emit('close')
        }
      }
    }
  }
</script>

<style scoped>
  .slide-scale-enter-from {
    opacity: 0;
    transform: translateY(30px);
  }

  .slide-scale-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }

  .slide-scale-enter-active {
    transition: all 270ms ease-out 180ms;
  }

  .slide-scale-leave-active {
    transition: all 270ms ease-in;
  }

  .slide-scale-enter-to,
  .slide-scale-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
</style>
