<template>
  <div
    v-if="notifications"
    class="
      lg:w-108
      fixed
      z-40
      bottom-0
      left-0
      top-20
      w-full
      h-auto
      bg-white
      border border-t-0 border-gray
      md:z-20
      md:left-64
      md:top-0
      md:w-96
    "
  >
    <div class="p-4 h-full overflow-y-auto md:pb-20 lg:p-8">
      <h2 class="text-black text-3xl font-bold tracking-tight leading-tight">Notifications</h2>

      <ul v-if="hasNotification" class="relative z-10 mt-4 space-y-4 lg:mt-8 lg:space-y-6">
        <AtomNotification v-for="notification in notifications" :key="notification.uid" :notification="notification" />
      </ul>
      <p v-else class="mt-4 lg:mt-8">No notifications</p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        notifications: 'notifications/getNotifications'
      }),
      hasNotification() {
        return this.notifications && !!this.notifications.length
      }
    }
  }
</script>
