<template>
  <div v-if="filters && !!filters.fields" class="px-4 border-b border-gray lg:px-8">
    <MoleculeFilters
      :fields="filters.fields"
      classes="max-w-screen-2xl mx-auto mt-4 lg:mt-6 lg:flex pb-8 lg:items-center"
      @update-content="update"
    />
  </div>

  <div v-if="hasEvents && !isLoading" :class="[form && !!!form.fields.length ? 'mt-4' : null]">
    <MoleculeEventList :events="events" :full="full" :simple="simple" />
  </div>
  <div v-else-if="!hasEvents && !isLoading" class="p-4 lg:p-8"><p>No events found</p></div>
  <h2 v-else class="flex mt-4 px-4 text-black text-2xl font-bold tracking-tight leading-tight lg:px-8">Loading…</h2>
</template>

<script>
  import MoleculeFilters from '@/components/molecules/Filters'

  export default {
    components: {
      MoleculeFilters
    },
    props: {
      events: Array,
      filters: Object,
      isLoading: Boolean,
      full: {
        type: Boolean,
        default: false
      },
      simple: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update-content'],
    computed: {
      hasEvents() {
        return this.events && !!this.events.length
      }
    },
    methods: {
      update(filters) {
        this.$emit('update-content', filters)
      }
    }
  }
</script>
