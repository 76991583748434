<template>
  <div class="fixed z-20 inset-0" aria-hidden="true">
    <Transition
      enter-active-class="transition ease-out duration-150"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      appear
    >
      <div ref="panelBackground" class="absolute inset-0 bg-gray bg-opacity-90" @click="$emit('close')"></div>
    </Transition>
  </div>
  <Transition
    enter-active-class="transform transition ease-out duration-150 delay-150"
    enter-from-class="translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transform transition ease-in duration-150"
    leave-from-class="translate-x-0"
    leave-to-class="-translate-x-full"
    appear
  >
    <div class="fixed z-50 bottom-0 right-0 top-0 3xl:w-1/3 w-full h-full bg-white md:w-auto lg:w-6/12">
      <div class="p-4 h-full overflow-hidden overflow-y-scroll lg:p-8">
        <div class="flex items-center justify-between mt-2 w-full sm:mt-20 md:mt-2 lg:mt-0">
          <h2 class="mr-4 text-black text-3xl font-bold tracking-tight leading-tight lg:mr-8">
            {{ panel.title }}
          </h2>
          <button
            class="
              group
              lg:hover:bg-orange-500
              lg:hover:border-orange-500
              flex
              items-center
              justify-center
              ml-auto
              w-10
              h-10
              text-sm
              font-medium
              bg-white
              border border-gray
              rounded-full
              focus:outline-none
              overflow-hidden
              transform
              transition
              duration-150
              ease-in-out
            "
            title="Close panel"
            @click="$emit('close')"
          >
            <BaseIcon
              class="lg:group-hover:text-white w-10 text-black transform transition-all duration-150 ease-in-out"
            >
              <CloseIcon />
            </BaseIcon>
          </button>
        </div>
        <div class="mt-4 w-full md:w-auto lg:mt-8">
          <MoleculeForm
            v-if="form"
            :initial-values="form.initial"
            :fields="form.fields"
            :cta="form.cta"
            :action="form.action"
            :classes="form.classes"
            :is-saving="isSaving"
            @form-submitted="submitForm"
          />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    provide: function () {
      return {
        panel: this
      }
    },
    emits: ['close', 'form-success'],
    data() {
      return {
        isSaving: false
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panel: 'getPanel'
      }),
      form() {
        return this.panel.form
      }
    },
    methods: {
      async submitForm(values) {
        this.isSaving = true

        try {
          const res = await this.apiRequest(
            this.panel.endpoint,
            values,
            null,
            { Authorization: this.userJWT },
            this.panel.method ? this.panel.method : 'POST'
          )
          let panelAction = null

          if (this.panel.action) {
            panelAction = this.panel.action
          } else if (res.slug) {
            const path = this.panel.endpoint.split('/')

            panelAction = `/${path[1]}/${res.slug}`
          }

          this.$store.dispatch('setDialog', {
            title: 'Success',
            content: this.panel.successMessage ? `<p>${this.panel.successMessage}</p>` : `<p>${res.message}</p>`,
            ctaTitle: this.panel.ctaTitle ? this.panel.ctaTitle : 'Close',
            confirmed: true,
            action: panelAction
          })

          this.$emit('form-success', res)
        } catch (e) {
          this.$store.dispatch('setDialog', {
            title: e && e.data && e.data.title ? e.data.title : 'Error',
            content: `<p>${e && e.data && e.data.message ? e.data.message : this.panel.errorMessage}</p>`,
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isSaving = false
      }
    }
  }
</script>
